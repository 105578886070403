<template>
  <div class="single-sats-wrapper">
    <div class="remaining-credit">
      <h5>موجودی</h5>
      <h6 v-text="remain?.toLocaleString()" />
    </div>
    <div class="sats" @click="expandPanel">
      <div class="sats-info">
        <span class="right-line" />
        <div class="right-title">
          <h2 v-text="titleText" />
        </div>
        <h3
          :class="rightLinePicker"
          v-if="status == 13"
          v-text="price?.toLocaleString()"
        />
        <h3 :class="rightLinePicker" v-else v-text="total?.toLocaleString()" />
      </div>
      <div class="down-btn" v-show="accordionOpen" @click="expandPanel">
        <svg
          @click="expandPanel"
          viewBox="0 0 451.847 451.847"
          :class="{ open: accordionOpen }"
          :fill="darkMode ? '#8a8a8a' : '#999'"
        >
          <path
            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
          />
        </svg>
      </div>
      <div class="bottom-sep" :class="{ plain: accordionOpen }">
        <span />
      </div>
    </div>
    <div
      class="accordion-1"
      :class="{ open: accordionOpen, close: !accordionOpen }"
    >
      <div
        class="accordion-2"
        :class="{
          open: accordionOpen2,
          close: !accordionOpen2
        }"
      >
        <div class="sats-details">
          <div class="trans-info">
            <div class="time">
              <span v-text="date" />-<span v-text="hour" />
            </div>
            <div class="table" v-if="tableType === 'typical'">
              <div class="stat-col">
                <h5>تعداد</h5>
                <h6>{{ count?.toLocaleString() }}</h6>
              </div>
              <i class="table__vr" />
              <div class="stat-col">
                <h5>مبلغ واحد</h5>
                <h6 class="rial">{{ price?.toLocaleString() }}</h6>
              </div>
              <i class="table__vr" />
              <div class="stat-col">
                <h5>مبلغ کل</h5>
                <h6 class="rial">{{ total?.toLocaleString() }}</h6>
              </div>
            </div>
            <div
              class="table table--two-col"
              v-else-if="tableType === 'package'"
            >
              <div class="stat-col">
                <h5>تعداد روز</h5>
                <h6>{{ count }}</h6>
              </div>
              <i class="table__vr" />
              <div class="stat-col">
                <h5>مبلغ</h5>
                <h6 class="rial">{{ total?.toLocaleString() }}</h6>
              </div>
            </div>
            <div class="table table--one-col" v-else>
              <div class="stat-col">
                <h5>کد رهگیری</h5>
                <h6>8523478089711598723</h6>
              </div>
            </div>
            <a
              class="table__btn"
              v-if="tableType === 'typical' && status != 1"
              @click="routeFinder"
            >
              مشاهده
            </a>
          </div>
        </div>
        <div class="sep" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransactionSingle',
  props: {
    status: [String, Number],
    hour: {
      type: String
    },
    date: {
      type: String
    },
    remain: {
      type: [String, Number]
    },
    price: {
      type: [String, Number]
    },
    isSuccess: {
      type: [String, Number]
    },
    relation: {
      type: [String, Number]
    },
    count: {
      type: [String, Number]
    },
    total: {
      type: [String, Number]
    }
  },
  data() {
    return {
      darkMode: false,
      accordionOpen: false,
      accordionOpen2: false
    }
  },
  methods: {
    changeTheme(state) {
      this.darkMode = !state
    },
    expandPanel() {
      this.$emit('expand')
      this.accordionOpen = !this.accordionOpen
      if (this.accordionOpen) {
        setTimeout(() => {
          const fixedNavHeight = 50
          const { bottom } = this.$el.getBoundingClientRect()
          const limit = window.innerHeight - fixedNavHeight - bottom
          if (limit < 0) {
            window.scrollBy({ top: -limit, behavior: 'smooth' })
          }
        }, 200)
      }
      this.accordionOpen2 = !this.accordionOpen2
    },
    routeFinder() {
      switch (this.status) {
        // credit
        case 2:
          this.$router.push({
            name: 'PlanShow',
            params: { id: this.relation }
          })
          break
        // offer
        case 3:
          this.$router.push({
            name: 'OfferShow',
            params: { id: this.relation }
          })
          break
        // gift
        case 4:
          this.$router.push({
            name: 'GiftList',
            params: { id: this.relation }
          })
          break
        // bonus
        case 5:
          this.$router.push({
            name: 'BonusShow',
            params: { id: this.relation }
          })
          break
        // event
        case 6:
          this.$router.push({
            name: 'EventEdit',
            params: { id: this.relation, mode: 'add' }
          })
          break
        // reminder
        case 7:
          this.$router.push({
            name: 'ReminderNew',
            params: { id: this.relation, mode: 'add' }
          })
          break
        // notice
        case 8:
          this.$router.push({
            name: 'NoticeShow',
            params: { id: this.relation }
          })
          break
        // survey
        case 9:
          this.$router.push({
            name: 'SurveyShow',
            params: { id: this.relation }
          })
          break
        // customer
        case 10:
          this.$router.push({
            name: 'CustomerShow',
            params: { id: this.relation }
          })
          break
        // lottery
        case 11:
          this.$router.push({
            name: 'LotteryWinner',
            params: { id: this.relation }
          })
          break
        default:
          return
      }
    }
  },
  computed: {
    titleText() {
      switch (this.status) {
        case 1:
          return 'ثبت فروش'
        case 2:
          return 'ایجاد طرح اعتباری'
        case 3:
          return 'ایجاد آفر'
        case 4:
          return 'ایجاد بن خرید'
        case 5:
          return 'ایجاد بونوس'
        case 6:
          return 'ارسال مناسبت ‌ها'
        case 7:
          return 'ارسال یادآوری'
        case 8:
          return 'ارسال اطلاع رسانی'
        case 9:
          return 'ارسال نظرسنجی'
        case 10:
          return 'ثبت مشتری'
        case 11:
          return 'ایجاد قرعه کشی'
        case 12:
          return 'اشتراک پذیرنده'
        case 13:
          if (this.isSuccess != 1) return 'تراکنش ناموفق'
          else return 'افزایش موجودی'
        case 14:
          return 'افزایش اشتراک'
        case 15:
          return 'پرداخت از اعتبار'
        default:
          return ''
      }
    },
    rightLinePicker() {
      if (this.status == 13) {
        if (this.isSuccess != 1) return 'gray'
        else return 'plus'
      } else return 'minus'
    },
    tableType() {
      if ((this.status >= 1 && this.status < 12) || this.status === 15)
        return 'typical'
      else if (this.status === 13) return 'pay'
      else return 'package'
    }
  }
}
</script>

<style scoped lang="scss">
div.single-sats-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .remaining-credit {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 25px;
    background-color: #ddd;
    h5 {
      font-size: 14px;
      height: 100%;
      line-height: 35px;
      margin: 0;
      color: #777;
    }
    h6 {
      padding-top: 2px;
      position: relative;
      color: #777;
      height: 100%;
      line-height: 35px;
      margin: 0;
      &::after {
        position: absolute;
        content: 'R';
        color: #777;
        font-size: 10px;
        right: -8px;
        top: -2px;
      }
    }
  }

  div.sats {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    background-color: #fff;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    // box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);

    .sats-info {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 25px;
      .right-line {
        background-color: #777777;
        position: absolute;
        margin-left: 10px;
        width: 5px;
        height: 98%;
        top: 0px;
        bottom: 0;
        margin: auto 0;
        right: 0;
        z-index: 2;
        border-radius: 5px 0 0 5px;
      }
      .right-title {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h2 {
          position: relative;
          font-size: 14px;
          margin: 0;
          color: #1f1f1f;
        }
      }

      h3 {
        direction: ltr;
        text-align: left;
        position: relative;
        font-size: 18px;
        // font-weight: bold;
        margin: 0;
        padding-top: 6px;
        color: #535353;
        &::before {
          position: absolute;
          left: -12px;
        }
        &::after {
          position: absolute;
          content: 'R';
          // color: #aaa;
          font-size: 10px;
          right: -10px;
          top: 6px;
        }
        &.plus {
          color: #00c209;
        }
        &.minus {
          color: #d40000;
        }
        &.plus::before {
          content: '+';
        }
        &.minus::before {
          content: '-';
        }
      }
    }

    .bottom-sep {
      height: 1px;
      padding: 0;
      margin: 0;
      position: relative;
      overflow: hidden;
      border-radius: 0;
      background-color: #fff;
      width: 100%;
      transition: background-color 0.3s ease;
      &.plain {
        border-radius: 0;
      }
      span {
        position: absolute;
        transition: all 0.3s ease;
        background-color: #e0e0e0;
        height: 1px;
        width: 100%;
        margin: 0 auto;
        right: 0;
        left: 0;
        bottom: 0px;
      }
    }

    div.down-btn {
      position: absolute;
      cursor: pointer;
      width: 45px;
      height: 45px;
      right: 0;
      left: 0;
      margin: 0 auto;
      bottom: -15px;
      border-radius: 50%;
      background-color: #fff;
      z-index: 1;
      transform: scaleX(1.5);
      box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease;
      &.dark {
        background-color: rgb(31, 31, 31);
      }

      svg {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        transform: scale(0.33, 0.5);
        &.open {
          transform: rotate(180deg) scale(0.33, 0.5);
        }
      }
    }
  }

  div.accordion-1 {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    margin: 0 0 0;
    padding: 0;
    max-height: 0;
    &.open {
      max-height: 700px;
    }
    &.close {
      max-height: 0;
    }
    .accordion-2 {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0 0 0;
      padding: 0;
      background-color: #1a1a1a;
      max-height: 700px;
      &.open {
        opacity: 1;
      }
      &.close {
        opacity: 0;
      }
      .sats-details {
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px 0;

        p {
          text-align: justify;
          color: #8a8a8a;
          padding: 0 10px;
          margin-bottom: 0;
        }
        .trans-info {
          width: 94%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          .table {
            margin: 15px 0 0;
            background-color: #222;
            width: 100%;
            border: none;
            display: flex;
            flex-direction: row;
            // grid-template-columns: 1fr 1px 1fr 1px 1fr;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            height: 72px;
          }
          .stat-col {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            color: #8a8a8a;
            height: 70px;
            h5 {
              font-size: 14px;
              margin: 0;
              // font-weight: bold;
            }
            h6 {
              font-size: 16px;
              margin-bottom: 0;
              // font-weight: bold;
              text-align: left;
              position: relative;
              &.rial::after {
                position: relative;
                content: 'R';
                color: #8a8a8a;
                font-size: 10px;
                right: -1px;
                top: -4px;
              }
              // color: #8a8a8a;
              &.minus {
                color: #d40000;
              }
              &.minus::before {
                position: absolute;
                left: -12px;
                content: '-';
              }
              &.percent::after {
                content: '%';
                color: #8a8a8a;
                top: 0px;
                left: 3px;
                position: relative;
              }
            }
          }
          .table--one-col {
            .stat-col {
              width: 100%;
            }
          }
          .table--two-col {
            .stat-col {
              width: calc(50% - 1px);
            }
          }
          .table__vr {
            height: 60%;
            width: 1px;
            background-color: #050505;
          }
          .table__btn {
            cursor: pointer;
            margin-top: 20px;
            width: 100px;
            height: 40px;
            line-height: 40px;
            color: #8a8a8a;
            text-align: center;
            border-radius: 15px;
            box-shadow: 0 0 5px 1px rgba(#8a8a8a, 0.2);
          }
          .offer-type {
            background-color: #1a1a1a;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px 0 10px;
            padding: 0;
            img {
              border-radius: 10px;
              width: 200px;
              height: 160px;
              box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
            }
            h4 {
              color: white;
              font-size: 16px;
              margin: 15px 0 10px 0;
            }
          }
        }
      }
      .sep {
        width: 100%;
        height: 1px;
        background-color: #fff;
      }
    }
  }
}
.time {
  direction: ltr;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  margin: 15px 0 0;
  span {
    margin: 0 5px;
  }
}
</style>
