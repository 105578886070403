<template>
  <div class="cmp-wrapper">
    <navbar msg="بسته ها" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="cmp-items-wrapper">
        <package-card
          v-for="pack in packages"
          :key="pack['id']"
          :price-new="pack['price_final'] / 10"
          :price-old="pack['price'] / 10"
          :package-name="pack['name']"
          :package-desc="pack['desc']"
          :package-percent="pack['percent']"
          @userClicked="handleSelectPackage(pack)"
        />
      </div>
    </section>
    <failed-mode @refresh="fetchPackages" v-else />
    <modal-confirm
      :is-open="modals['confirm']"
      :is-loading="btnLoading"
      @modalconfirmclosed="modals['confirm'] = false"
      @modalconfirmcancelled="modals['confirm'] = false"
      @confirm="handleActivatePackage"
      :text="`آیا از خرید &quot;${selectedCard['name']}&quot; مطمئن هستید؟`"
    />
    <fixed-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getPackage } from '@service/ProductService'
import PackageCard from './subComponents/PackageCard'

export default {
  name: 'Package',
  components: {
    PackageCard
  },
  data() {
    return {
      selectedCard: { name: '' },
      btnLoading: false,
      modals: {
        confirm: false
      }
    }
  },
  methods: {
    ...mapActions({
      addToast: 'addToast',
      handleNoPackage: 'handleNoPackage',
      fetchHomeData: 'fetchHomeData',
      fetchPackages: 'product/fetchPackages',
      clearPackages: 'product/clearPackages',
      handlePayModal: 'profile/handlePayModal'
    }),
    handleSelectPackage(id) {
      this.selectedCard = id
      this.modals['confirm'] = true
    },
    async handleActivatePackage() {
      if (this.btnLoading) return
      this.btnLoading = true
      try {
        await getPackage(this.selectedCard['id'])
        this.addToast({
          type: 'success',
          message: 'بسته با موفقیت فعال گردید.'
        })
        this.handleNoPackage(false)
        this.fetchHomeData()
        this.$router.push({ name: 'Home' })
      } catch (ex) {
        if (ex.response) {
          switch (ex.response.status) {
            case 400:
              this.addToast({
                type: 'error',
                message: 'فعالسازی بسته با خطا مواجه شد.'
              })
              break

            case 402:
              this.addToast({
                type: 'error',
                message: 'موجودی حساب شما برای خرید بسته کافی نمی‌باشد.'
              })
              this.handlePayModal('open')
              break

            case 404:
              this.addToast({
                type: 'error',
                message: 'بسته مورد نظر یافت نشد.'
              })
              break

            default:
              break
          }
        }
      } finally {
        this.btnLoading = false
        this.modals['confirm'] = false
      }
    }
  },
  computed: {
    ...mapState({
      pageStatus: (state) => state.product.pageStatus,
      packages: (state) => state.product.packages
    })
  },
  created() {
    this.fetchPackages()
  },
  beforeUnmount() {
    this.clearPackages()
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  padding: 80px 0 75px;
}
.load-pending--show {
  height: calc(100vh - 155px);
}
</style>
