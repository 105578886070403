<template>
  <div class="video-wrapper" v-if="isOpen">
    <button class="video__btn btn--close" @click="$emit('player-closed')">
      <svg viewBox="0 0 512 512">
        <path
          d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
        />
      </svg>
    </button>
    <video
      @loadstart="handleStart"
      @play="handlePlay"
      @pause="handlePause"
      @timeupdate="handleTimeUpdate"
      @ended="handleEnded"
      class="video"
      controls
      ref="video"
      poster="@img/preload-logo-dark.svg"
    >
      <!-- <source src="@img/test-video.mp4" /> -->
    </video>
    <div class="video__controls" ref="control" v-if="showControl">
      <button class="video__btn" @click="togglePlay">
        <svg v-if="!isPlaying" class="video__icon" viewBox="0 0 448 512">
          <path
            d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
          ></path>
        </svg>
        <svg v-else class="video__icon" viewBox="0 0 448 512">
          <path
            d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z"
          ></path>
        </svg>
      </button>
      <div class="video__progress" @click="setProgress">
        <i class="video__seek" :style="{ width: seekWidth }" />
      </div>
      <div class="video__time">
        <time>{{ currentTime }}</time>
        <span> / </span>
        <time>{{ duration }}</time>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref, watchEffect } from 'vue'
import dateConvertor from '@composable/dateConvertor'
export default {
  name: 'BasePlayer',
  emits: ['player-closed'],
  props: {
    isOpen: { type: Boolean, default: false }
  },
  setup(props) {
    const video = ref(null)
    const control = ref(null)
    const showControl = ref(false)
    const isPlaying = ref(false)
    const seekWidth = ref(0)
    const { formatTime } = dateConvertor()
    const duration = ref('')
    const currentTime = ref('00:00')

    const togglePlay = () => {
      if (video.value.ended || video.value.paused) {
        video.value.play()
      } else {
        video.value.pause()
      }
    }

    const handleStart = () => {
      const videoWorks = !!document.createElement('video').canPlayType
      if (videoWorks) {
        video.value.controls = false
        showControl.value = true
        video.value.play()
      }
    }

    const handlePlay = () => {
      isPlaying.value = true
    }

    const handlePause = () => {
      isPlaying.value = false
    }

    const handleEnded = () => {
      isPlaying.value = false
    }

    const handleTimeUpdate = ($event) => {
      const { currentTime: curr, duration: dur } = $event.target
      // setTime
      if (!duration.value) {
        duration.value = formatTime(dur)
      }
      currentTime.value = formatTime(curr)
      // Set seeker width
      seekWidth.value = (curr / dur) * 100 + '%'
    }

    const setProgress = ($event) => {
      // seek pointer event is nont
      const width = $event.target.clientWidth
      const offset = $event.offsetX
      const duration = video.value.duration

      video.value.currentTime = (offset / width) * duration
      seekWidth.value = (offset / width) * 100 + '%'
    }

    // Handle Modal
    const applyModalEffect = () => {
      const body = document.querySelector('body')
      body.style.top = `-${window.scrollY}px`
      body.style.position = 'fixed'
    }
    const removeModalEffect = () => {
      const body = document.querySelector('body')
      const scrollY = document.body.style.top
      body.style.position = ''
      body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
    watchEffect(() => {
      if (props.isOpen) {
        applyModalEffect()
      } else {
        isPlaying.value = false
        seekWidth.value = 0
        duration.value = ''
        currentTime.value = '00:00'
        removeModalEffect()
      }
    })

    onBeforeUnmount(removeModalEffect)

    return {
      video,
      control,
      showControl,
      togglePlay,
      isPlaying,
      handlePlay,
      handlePause,
      handleTimeUpdate,
      seekWidth,
      setProgress,
      handleEnded,
      duration,
      currentTime,
      handleStart
    }
  }
}
</script>

<style lang="scss" scoped>
.video-wrapper {
  position: fixed;
  z-index: 201;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000, 1);
  padding-top: 50px;
}
.video {
  width: 100%;
  max-width: 1000px;
}
.video__controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  direction: ltr;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.video__btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--close {
  position: absolute;
  left: 10px;
  top: 10px;
  svg {
    width: 30px;
    height: 30px;
    fill: #777;
  }
}
.video__icon {
  width: 35px;
  height: 35px;
  fill: #fff;
}
.video__time {
  width: 100px;
  color: #fff;
  font-size: 14px;
}
.video__progress {
  width: 80%;
  height: 20px;
  border-radius: 5px;
  background-color: rgba(#fff, 0.3);
  cursor: pointer;
}
.video__seek {
  display: block;
  width: 0;
  border-radius: 5px;
  height: 100%;
  background-color: rgba(#fff, 0.9);
  // transition: width 0.1s linear;
  pointer-events: none;
}
</style>
