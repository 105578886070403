<template>
  <div class="cmp-wrapper">
    <navbar msg="دریافت تخفیف" />
    <section class="load-pending load-pending--show" v-if="!resolved">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <div class="customer">
        <div class="customer-info">
          <div class="customer-img">
            <img :src="customer['image']" v-show="flip" @load="flip = !flip" />
            <svg
              v-if="!flip"
              style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
              viewBox="0 0 512 512"
            >
              <circle fill="#606062" cx="256" cy="256" r="256" />
              <path
                fill="#373435"
                d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
              />
            </svg>
          </div>
          <div class="customer-title">
            <h2>
              <span style="color: #858585">
                {{ customer['gender'] == 1 ? 'آقای' : 'خانم' }}
              </span>
              {{ customer['name'] }}
            </h2>
          </div>
        </div>
        <!-- <i class="customer-hr" /> -->
        <div class="customer-credit">
          <h3 class="cutomer-credit-title">
            اعتبار:
          </h3>
          <h4 class="customer-credit-number">
            {{ customer['credit']?.toLocaleString() }}
          </h4>
        </div>
        <div class="credit-buttons" style="width: 96%;">
          <a
            class="button button--black"
            @click="
              () => {
                modals['credit'] = true
                $nextTick(focusOnInput)
              }
            "
          >
            <span class="button__text" style="color: #c69d4e">دریافت</span>
          </a>
        </div>
      </div>
      <div class="cmp-items-wrapper">
        <h4 class="cmp-title" v-if="offerItems.length > 0">آفرها</h4>
        <offer-item-single
          v-for="item in offerItems"
          :key="item.id"
          :id="item.id"
          :title="item.offer['title']"
          :price-old="item.offer['price']"
          :price-new="item.offer['price_final']"
          :create="item.offer['created_at']"
          :expire="item.offer['expire_at']"
          :image="item.offer['image']"
          :desc="item.offer['desc']"
          @offerreceived="getDiscounts"
        />
        <h4
          class="cmp-title"
          style="margin-top: 30px"
          v-if="bonusItems.length > 0"
        >
          بونوس ها
        </h4>
        <bonus-item-single
          v-for="item in bonusItems"
          :key="item.id"
          :id="item.id"
          :create="item.bonus['created_at']"
          :expire="item.bonus['expire_at']"
          :desc="item.bonus['desc']"
          :price="item.bonus['price']"
          :min-buy="item.bonus['min_buy']"
          @bonusreceived="getDiscounts"
        />
      </div>
    </section>
    <modal
      :is-open="modals['credit']"
      @modalClosed="modals['credit'] = false"
      height="158px"
    >
      <form novalidate class="pay" @submit.prevent="handleReceiveCredit">
        <base-input-number
          label="مبلغ"
          star
          v-model="payItems['price']"
          ref="inputNumber"
        />
        <div class="credit-buttons" style="width: 90%; margin: 20px 0 0;">
          <button class="button button--black">
            <span class="button__text" style="color: #c69d4e">دریافت</span>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
    </modal>
    <modal
      :is-open="modals['confirm']"
      @modalClosed="modals['confirm'] = false"
      height="158px"
    >
      <form novalidate class="pay" @submit.prevent="handleConfirmCredit">
        <base-input-little
          ref="confirmCode"
          @finishType="
            (code) => {
              $refs['confirmCodeBtn'].focus()
              payItems['code'] = code
            }
          "
        />
        <div class="credit-buttons" style="width: 90%; margin: 20px 0 0;">
          <button class="button button--black" ref="confirmCodeBtn">
            <span class="button__text" style="color: #c69d4e">دریافت</span>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
    </modal>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, nextTick, reactive, ref } from 'vue'
import OfferItemSingle from '@view/store/offer/subComponents/OfferItemSingle'
import BonusItemSingle from '@view/store/bonus/subComponents/BonusItemSingle'
import BaseInputNumber from '@common/BaseInputNumber'
import {
  getInquiries,
  receiveCredit,
  confirmReceiveCredit
} from '@service/TransactionService'
import BaseInputLittle from '@common/BaseInputLittle'
import { schemaMaker, price, validateForms } from '@valid/JoiValidation'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'FooterInquiry',
  props: ['mobile'],
  components: {
    BaseInputNumber,
    OfferItemSingle,
    BonusItemSingle,
    BaseInputLittle
  },
  setup(props) {
    const customer = reactive({
      name: '',
      image: '',
      credit: 0,
      gender: 1
    })
    const offerItems = ref(null)
    const bonusItems = ref(null)
    const resolved = computed(() => !!offerItems.value && !!bonusItems.value)
    const flip = ref(false)
    const router = useRouter()
    const getDiscounts = async () => {
      try {
        const { data } = await getInquiries(props.mobile)
        offerItems.value = data['offer_list']
        bonusItems.value = data['bonus_list']

        customer['name'] = data.name
        customer['image'] = data.image
        customer['credit'] = data.amount
        customer['gender'] = data.gender
      } catch (ex) {
        if (ex.response)
          switch (ex.response.status) {
            case 404:
              store.dispatch('addToast', {
                type: 'error',
                message: 'مشتری با این شماره ثبت نشده است.'
              })
              router.push({ name: 'Home' })
              break

            default:
              router.push({ name: 'Home' })
              break
          }
      }
    }
    getDiscounts()

    /*************
     * PAY ITEMS *
     *************/
    const store = useStore()
    const payItems = reactive({
      price: '',
      code: ''
    })
    const modals = reactive({
      credit: false,
      confirm: false
    })
    const inputNumber = ref(null)
    const focusOnInput = () => {
      inputNumber.value.$el.focus()
      // nextTick(() => {
      // window.scrollTo({ top: 300 })
      // })
    }
    const btnLoading = ref(false)
    const confirmCode = ref(null)
    const handleReceiveCredit = async () => {
      const validPrice = schemaMaker({
        price: price('اعتبار', 10000)
      })
      const errors = validateForms({ price: payItems['price'] }, validPrice)

      if (errors && errors.length > 0) {
        for (let key of errors) {
          const message = { type: 'error', message: key }
          store.dispatch('addToast', message)
        }
        return
      }

      btnLoading.value = true
      try {
        await receiveCredit({ mobile: props.mobile, price: payItems['price'] })
        modals['credit'] = false
        modals['confirm'] = true
        nextTick(() => confirmCode.value.$el.querySelector('input').focus())
      } catch (ex) {
        if (ex.response && ex.response.status == 402) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'مبلغ دریافتی از موجودی اعتبار بیشتر است.'
          })
        }
      } finally {
        btnLoading.value = false
      }
    }
    const handleConfirmCredit = async () => {
      btnLoading.value = true
      try {
        await confirmReceiveCredit({
          mobile: props.mobile,
          code: payItems['code']
        })
        modals['confirm'] = false
        modals['credit'] = false
        payItems['price'] = ''
        payItems['code'] = ''
        store.dispatch('addToast', {
          type: 'success',
          message: 'اعتبار با موفقیت دریافت شد.'
        })
        store.dispatch('addToast', {
          type: 'success',
          message: 'مبلغ 1000 ریال از موجودی شما پرداخت شد.'
        })
        store.dispatch('fetchHomeData')
        getDiscounts()
      } catch (ex) {
        if (ex.response)
          switch (ex.response.status) {
            case 403:
              store.dispatch('addToast', {
                type: 'error',
                message: 'کد وارد شده صحیح نمی‌باشد.'
              })
              break
            case 402:
              store.dispatch('addToast', {
                type: 'error',
                message: 'موجودی حساب شما کافی نمی‌باشد.'
              })
              break
            case 400:
              store.dispatch('addToast', {
                type: 'error',
                message: 'زمان اعتبار کد ارسال شده پایان یافته است.'
              })
              break

            default:
              break
          }
      } finally {
        btnLoading.value = false
      }
    }

    return {
      customer,
      offerItems,
      bonusItems,
      getDiscounts,
      resolved,
      flip,
      modals,
      inputNumber,
      focusOnInput,
      payItems,
      btnLoading,
      handleReceiveCredit,
      handleConfirmCredit,
      confirmCode
    }
  }
}
</script>

<style scoped lang="scss">
.customer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 60px 0 0;
  margin-bottom: 20px;

  .customer-info {
    width: 100%;
    padding: 15px 0 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .customer-img {
      padding: 0;
      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        filter: saturate(0.3);
        box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
      }
      svg {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
      }
    }

    .customer-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h2 {
        color: black;
        margin: 15px 0 0;
        text-align: right;
        font-size: 16px;
      }
      h3 {
        color: #777;
        margin: 0 0 8px;
        text-align: right;
        font-size: 16px;
      }
    }
  }
  .customer-hr {
    width: 40%;
    height: 1px;
    background-color: rgba(#000, 0.2);
    margin-bottom: 15px;
  }
  .customer-credit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .customer-credit-number {
    margin-right: 10px;
    &::after {
      content: 'ریال';
      // font-size: 12px;
      position: relative;
      // top: -3px;
      margin-right: 5px;
    }
  }
}
.pay {
  width: 100%;
  padding: 20px 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to top, #bbb 0, #fff 55px);
}
.pay__title {
  font-size: 18px;
  margin-bottom: 15px;
}

// change in common css
.cmp-wrapper {
  padding: 0 0 75px;
  background-color: #fff;
}
.cmp-title {
  width: 100%;
  text-align: center;
  margin: 10px 0 15px;
  font-size: 22px;
}
.credit-buttons {
  margin-top: 5px;
}
.credit-buttons .button {
  width: 100%;
}
.load-pending--show {
  padding-top: 60px;
  height: calc(100vh - 75px);
}
</style>
