<script setup>
import { ref, computed, toRefs, reactive, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  reqString,
  schemaMaker,
  reqMobile,
  validateForms
} from '@valid/JoiValidation'
import { sendLoginData, sendConfirmData, getTokens } from '@service/AuthService'
import { setTempData } from '@service/TokenService'

// Main items
const store = useStore()
const router = useRouter()
const current = ref('mobile')
const currentComponent = computed(() => {
  if (current.value == 'mobile')
    return defineAsyncComponent(() => import('./subComponents/LoginForm.vue'))
  return defineAsyncComponent(() => import('./subComponents/LoginCode.vue'))
})
const form = reactive({
  mobile: '',
  code: ''
})
const { mobile, code } = toRefs(form)

// Login
const loading = ref(false)
const login = async () => {
  if (loading.value) return

  const isValid = schemaMaker({
    mobile: reqMobile()
  })

  const formData = { mobile: mobile.value }
  const errors = validateForms(formData, isValid)
  if (errors) return store.dispatch('displayErrors', errors)

  loading.value = true
  try {
    await sendLoginData(formData)
    current.value = 'code'
  } catch (ex) {
    if (ex.response && ex.response.status == 451)
      store.dispatch('addToast', {
        type: 'error',
        message: 'تعداد درخواست شما بیش از حد مجاز است.'
      })
    else if (ex.response && ex.response.status == 404)
      store.dispatch('addToast', {
        type: 'error',
        message: 'شماره موبایل در سیستم ثبت نشده است.'
      })
    else
      store.dispatch('addToast', {
        type: 'error',
        message: 'درخواست شما موفقیت‌آمیز نبود.'
      })
  } finally {
    loading.value = false
  }
}

// Confirm
const confirm = async () => {
  if (loading.value) return

  const formData = { code: code.value }
  const isValid = schemaMaker({
    code: reqString('کد تأیید')
  })

  const errors = validateForms(formData, isValid)
  if (errors) return store.dispatch('displayErrors', errors)

  formData['mobile'] = mobile.value
  loading.value = true
  try {
    const { data } = await sendConfirmData(formData)
    const nStores = data.stores.length

    if (nStores === 1) {
      const tokenForm = {
        token: data.token,
        store: data.stores[0].id,
        mobile: mobile.value
      }
      try {
        const { data: tokenData } = await getTokens(tokenForm)
        store.dispatch('loginUser', tokenData)

        if (tokenData.completed) router.push({ name: 'Home' })
        else router.push({ name: 'Signup' })
      } catch (ex) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
      }
    } else if (nStores > 1) {
      // work with local storage
      const tempData = {
        mobile: mobile.value,
        stores: data.stores,
        token: data.token
      }
      setTempData(tempData)
      router.push({ name: 'ProfileStore' })
    }

    // mobile.value = data.mobile
  } catch (ex) {
    if (ex.response && ex.response.status == 451)
      store.dispatch('addToast', {
        type: 'error',
        message: 'تعداد درخواست شما بیش از حد مجاز است.'
      })
    else if (ex.response && ex.response.status == 400)
      store.dispatch('addToast', {
        type: 'error',
        message: 'کد وارد شده اشتباه می‌باشد.'
      })
    else
      store.dispatch('addToast', {
        type: 'error',
        message: 'درخواست شما موفقیت‌آمیز نبود.'
      })
  } finally {
    loading.value = false
  }
}
const handleTimeFinished = () => {
  current.value = 'mobile'
  store.dispatch('addToast', {
    type: 'error',
    message: 'کد فعالسازی منقضی شد.'
  })
}
</script>

<template>
  <div class="login-wrapper">
    <img class="login__img" src="@img/login-bg.jpg" />
    <div class="login__logos">
      <img class="login__logo" src="@img/vipon-loading.svg" />
      <img
        class="login__logo logo--second"
        src="@img/vipon-loading-white.svg"
      />
    </div>
    <transition name="fade" mode="out-in">
      <component
        :is="currentComponent"
        :mobile="mobile"
        :code="code"
        :loading="loading"
        @loginClicked="login"
        @goBack="current = 'mobile'"
        @timeFinished="handleTimeFinished"
        @confirmClicked="confirm"
        @updateMobile="
          (value) => {
            mobile = value
          }
        "
        @updateCode="
          (value) => {
            code = value
          }
        "
      />
    </transition>
    <div class="login__copy">
      <p>All Rights Reserved By <a href="www.vipon.ir">VIPON</a></p>
      <p>&copy; 2021</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  padding: 350px 0 70px;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__copy {
  position: absolute;
  height: 50px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  direction: ltr;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 14px;
    color: #444;
  }
}
</style>
