<template>
  <div class="bonus-wrapper">
    <bonus-single :price="price" :min-buy="minBuy" />
    <div class="desc">
      <h4 class="desc__title">توضیحات</h4>
      <p class="desc__text" v-text="desc" />
    </div>
    <section class="time-wrapper">
      <p v-if="diffDay > 1">
        از
        <span>{{ localizeDate(create) }}</span>
        تا
        <span v-text="diffDay - 1" />
        روز
      </p>
      <p v-else>
        <span>{{ localizeDate(expire) }}</span>
      </p>
      <count-down
        :endDate="applyTimeZone(expire)"
        :permission="true"
        class="countdown"
      />
    </section>
    <div class="credit-buttons">
      <button class="button button--black" @click="disableBonusItem(id)">
        <span class="button__text">دریافت بونوس</span>
        <spinner v-if="btnLoading" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import CountDown from '@common/CountDown'
import dateConvertor from '@composable/dateConvertor'
import { disableTransactionBonus } from '@service/TransactionService'
import BonusSingle from '@view/store/bonus/subComponents/BonusSingle'

export default {
  name: 'BonusShow',
  components: {
    CountDown,
    BonusSingle
  },
  props: {
    id: [String, Number],
    expire: {
      type: String,
      default: new Date()
    },
    create: {
      type: String,
      default: new Date()
    },
    desc: String,
    price: {
      type: [String, Number],
      default: 0
    },
    minBuy: {
      type: [String, Number],
      default: 0
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const btnLoading = ref(false)
    const disableBonusItem = async (id) => {
      btnLoading.value = true
      try {
        await disableTransactionBonus({ id })
        store.dispatch('addToast', {
          type: 'success',
          message: 'بونوس با موفقیت دریافت شد.'
        })

        emit('bonusreceived')
      } catch (ex) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
      } finally {
        btnLoading.value = false
      }
    }

    const { localizeDate, getRemainingDay, applyTimeZone } = dateConvertor()
    const diffDay = computed(() => {
      return getRemainingDay(props.create, props.expire)
    })
    return {
      disableBonusItem,
      localizeDate,
      getRemainingDay,
      applyTimeZone,
      btnLoading,
      diffDay
    }
  }
}
</script>

<style lang="scss" scoped>
$dark-text-color: #444;
$dark-bg-color-primary: #080808;
$dark-bg-color-secondary: #181818;

.bonus-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eee;
  padding-top: 20px;
  margin-bottom: 20px;
  border-radius: 30px;
}
.desc {
  width: 100%;
  padding: 0 25px;
  margin: 5px 0 25px;
}
.desc__title {
  color: #000;
  width: 100%;
  margin: 10px 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.desc__text {
  color: #686868;
  width: 100%;
  margin: 5px 0 0;
  text-align: justify;
  text-align-last: center;
  font-size: 12px;
  padding: 0 3px;
}
.desc--dark .desc__title {
  color: #777;
  font-weight: normal;
}

.bonus__hr {
  width: 100%;
  border-top: 2px dashed rgba(0, 0, 0, 0.3);
}

// bonus top
.bonus-top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #fff 0, #f5f5f5 100%);
  padding: 20px 0 20px;
  border-radius: 0 0 15px 15px;
}
.bonus-top__title {
  margin: 0;
  text-align: center;
  color: #c69d4e;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.bonus-top__icon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: rgba(#c69d4e, 0.2);
  margin: 10px 0 10px;
  fill: #c69d4e;
}
.bonus-top__price {
  margin: 0 0 5px;
  font-size: 22px;
  &::after {
    content: 'R';
    font-size: 14px;
    position: relative;
    top: -5px;
    right: 0px;
    color: #888;
  }
}

// change in common css
.countdown {
  margin-top: 0;
  padding: 10px 0 15px;
  width: 100%;
}
.credit-buttons {
  margin: 20px 0 25px;
  .button--black {
    width: 96%;
    .button__text {
      color: #c69d4e;
    }
  }
}
</style>
