<template>
  <div class="package">
    <div class="package__header">
      <div class="package__icon-front">
        <svg viewBox="0 0 512 512">
          <path
            d="M256 388.99l208.79 -211.84c8.96,24.04 13.94,50.03 14.14,77.17l-222.93 226.18 -222.93 -226.18c0.2,-27.14 5.18,-53.13 14.14,-77.17l208.79 211.84zm0 -265.32l-77.22 -78.34c23.87,-8.87 49.65,-13.75 76.56,-13.83l0.66 0.67 0.66 -0.67c26.91,0.08 52.69,4.96 76.56,13.83l-77.22 78.34zm0 178.42l-178.29 -180.9c12.88,-17.25 28.18,-32.57 45.38,-45.46l132.91 134.85 132.91 -134.85c17.2,12.89 32.5,28.21 45.38,45.46l-178.29 180.9z"
          />
        </svg>
      </div>
      <svg class="package__icon-back" viewBox="0 0 512 512">
        <path
          d="M256 388.99l208.79 -211.84c8.96,24.04 13.94,50.03 14.14,77.17l-222.93 226.18 -222.93 -226.18c0.2,-27.14 5.18,-53.13 14.14,-77.17l208.79 211.84zm0 -265.32l-77.22 -78.34c23.87,-8.87 49.65,-13.75 76.56,-13.83l0.66 0.67 0.66 -0.67c26.91,0.08 52.69,4.96 76.56,13.83l-77.22 78.34zm0 178.42l-178.29 -180.9c12.88,-17.25 28.18,-32.57 45.38,-45.46l132.91 134.85 132.91 -134.85c17.2,12.89 32.5,28.21 45.38,45.46l-178.29 180.9z"
        />
      </svg>
      <h2 class="package__title" v-text="packageName" />
      <p class="package__desc">
        {{ packageDesc }}
      </p>
      <!-- <p class="package__discount">
        <span>-{{ packagePercent }}%</span>
      </p> -->
      <div class="package__prices">
        <h3
          class="package__price price--old"
          v-text="priceOld?.toLocaleString()"
        />
        <h3
          class="package__price price--new"
          v-text="priceNew?.toLocaleString()"
        />
      </div>
    </div>
    <form class="credit-buttons" @submit.prevent="$emit('user-clicked')">
      <button class="button button--pack" type="submit">
        <span class="button__text">فعالسازی</span>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'PackageCard',
  components: {},
  props: {
    priceOld: {
      type: [String, Number],
      default: 10000
    },
    priceNew: {
      type: [String, Number],
      default: 5000
    },
    packagePercent: {
      type: [String, Number],
      default: 0
    },
    packageName: {
      type: String,
      default: ''
    },
    packageDesc: {
      type: String,
      default: ''
    }
  },
  computed: {
    discount() {
      return this.priceNew && this.priceOld
        ? Math.abs(100 - Math.floor((this.priceNew / this.priceOld) * 100))
        : 0
    }
  }
}
</script>

<style scoped lang="scss">
%common {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}

.package {
  @extend %common;
  position: relative;
  width: 92%;
  margin: 18px 0;
  background: #fff;
  border-radius: 20px;
}

// ---------
.package__prices {
  @extend %common;
  width: 100%;
  margin: 0 0 20px;
}
.package__price {
  position: relative;
  color: #fff;
  margin: 0 0 5px;
  font-weight: bold;
  font-size: 24px;
  z-index: 1;
  &::after {
    position: relative;
    content: 'تومان';
    // right: -11px;
    // top: 2px;
    font-size: 14px;
    margin-right: 3px;
    bottom: 3px;
  }
}
.price--old {
  color: #777;
  &::before {
    content: '';
    position: absolute;
    width: 120%;
    background-color: rgba(#777, 0.6);
    height: 2px;
    top: 0;
    bottom: 6px;
    left: -10%;
    margin: auto 0;
  }
}
.price--new {
  color: #c69d4e;
}
.package__discount {
  border-radius: 0 10px 10px 0;
  // padding: 13px 20px 7px 10px;
  min-width: 85px;
  padding-right: 5px;
  height: 50px;
  line-height: 50px;
  direction: ltr;
  color: #000;
  font-size: 30px;
  position: absolute;
  left: 0px;
  top: 47px;
  background-color: #c69d4e;
  color: #fff;
  text-align: center;
  animation: blinking 1.3s infinite;
  span {
    display: inline-block;
    position: relative;
    top: 4px;
  }
}
.package__period {
  color: #fff;
  z-index: 1;
  font-size: 14px;
  margin: -5px 0 15px;
  position: relative;
  height: 30px;
  line-height: 30px;
  text-shadow: -2px 3px 5px rgba($color: #000000, $alpha: 0.3);
}
.package__header {
  @extend %common;
  position: relative;
  border-radius: 20px 20px 0 0;
  width: 100%;
}
.package__icon-front {
  position: absolute;
  z-index: 1;
  width: 55px;
  height: 55px;
  background-color: #c69d4e;
  border-radius: 50%;
  top: -23px;
  right: 0;
  left: 0;
  margin: 0 auto;
  // box-shadow: -2px 3px 10px 0px rgba(0, 0, 0, 0.5);

  svg {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    fill: #fff;
  }
}

.package__title {
  margin: 50px 0 10px;
  color: #000;
  font-size: 18px;
}
.package__desc {
  margin: 0 0 27px;
  color: #737373;
  font-size: 14px;
}
.package__icon-back {
  all: initial;
  position: absolute;
  top: 60px;
  bottom: 0;
  right: -100px;
  margin: auto 0;
  width: 200px;
  height: 200px;
  z-index: 0;
  fill: #eee;
  opacity: 0.7;
}

// change in common css
.credit-buttons {
  margin: 0 0 20px;
}
.button--pack {
  margin: 0;
  width: 90%;
  height: 60px;
  background-color: #000;
  border-radius: 10px;
  // box-shadow: 0 0 10px 1px rgba(#000, 0.2);
}
.button--pack .button__text {
  color: #c69d4e;
  font-size: 16px;
  // animation: blinking 1.3s infinite;
}
</style>
