<template>
  <div
    :class="['bonus', isDark && 'bonus--dark', bonusInner && 'bonus--inner']"
  >
    <div class="bonus__row">
      <svg class="bonus__icon" viewBox="0 0 512 512">
        <path
          d="M310.28 167.78l0.63 -0.05c6.98,-0.65 12.33,-6.5 12.33,-13.51 0,-7.01 -5.35,-12.87 -12.33,-13.51l-0.6 -0.06 -40.74 0 0 -43.42 -0.06 -0.62c-0.65,-6.97 -6.5,-12.34 -13.51,-12.34 -7.02,0 -12.87,5.36 -13.51,12.34l-0.06 0.59 0 43.45 -8.89 0c-35.44,0 -64.45,29.01 -64.45,64.46 0,35.44 29.01,64.46 64.45,64.46l44.91 0c20.54,0 37.33,16.78 37.33,37.32 0,20.54 -16.79,37.32 -37.33,37.32l-76.75 0 -0.61 0.06c-6.98,0.64 -12.34,6.5 -12.34,13.51 0,7.01 5.36,12.88 12.34,13.51l0.6 0.06 40.74 0 0 43.45 0.06 0.59c0.64,6.98 6.5,12.33 13.51,12.33 7.01,0 12.86,-5.36 13.51,-12.33l0.06 -0.62 0 -43.42 8.88 0c35.45,0 64.46,-29.02 64.46,-64.46 0,-35.44 -29.01,-64.46 -64.46,-64.46l-44.9 0c-20.54,0 -37.32,-16.78 -37.32,-37.32 0,-20.54 16.78,-37.33 37.32,-37.33l76.73 0z"
        />
      </svg>
      <h4
        :class="['bonus__price', !isDark && 'price--gold']"
        v-text="price?.toLocaleString()"
      />
      <h5 :class="['bonus__title', !isDark && 'title--gold']">بونوس</h5>
    </div>
    <div class="bonus__row">
      <svg class="bonus__icon icon--min" viewBox="0 0 512 512">
        <path
          d="M310.28 167.78l0.63 -0.05c6.98,-0.65 12.33,-6.5 12.33,-13.51 0,-7.01 -5.35,-12.87 -12.33,-13.51l-0.6 -0.06 -40.74 0 0 -43.42 -0.06 -0.62c-0.65,-6.97 -6.5,-12.34 -13.51,-12.34 -7.02,0 -12.87,5.36 -13.51,12.34l-0.06 0.59 0 43.45 -8.89 0c-35.44,0 -64.45,29.01 -64.45,64.46 0,35.44 29.01,64.46 64.45,64.46l44.91 0c20.54,0 37.33,16.78 37.33,37.32 0,20.54 -16.79,37.32 -37.33,37.32l-76.75 0 -0.61 0.06c-6.98,0.64 -12.34,6.5 -12.34,13.51 0,7.01 5.36,12.88 12.34,13.51l0.6 0.06 40.74 0 0 43.45 0.06 0.59c0.64,6.98 6.5,12.33 13.51,12.33 7.01,0 12.86,-5.36 13.51,-12.33l0.06 -0.62 0 -43.42 8.88 0c35.45,0 64.46,-29.02 64.46,-64.46 0,-35.44 -29.01,-64.46 -64.46,-64.46l-44.9 0c-20.54,0 -37.32,-16.78 -37.32,-37.32 0,-20.54 16.78,-37.33 37.32,-37.33l76.73 0z"
        />
      </svg>
      <h4 class="bonus__price" v-text="parseInt(minBuy)?.toLocaleString()" />
      <h5 class="bonus__title">حداقل خرید</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BonusSingle',
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    bonusInner: {
      type: Boolean,
      default: false
    },
    price: {
      type: [Number, String],
      default: 0
    },
    minBuy: {
      type: [Number, String],
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
$light-border: #ddd;
$dark-border: #383838;
$bonus-svg-width: 37px;
$dark-bg: #131313;
$parent-bg-color: #111;

.bonus {
  direction: ltr;
  width: 94%;
  height: 100px;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 15px;
  cursor: pointer;
}
.bonus__row {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr 100px;
  align-items: center;
  height: 40px;
}
.bonus__icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgba(#c69d4e, 0.2);
  margin: 0 10px;
  fill: #c69d4e;
}
.icon--min {
  background-color: rgba(#777, 0.2);
  fill: #777;
}
.bonus__price {
  color: #777;
  margin: 0;
  font-size: 22px;
  padding-left: 10px;
  top: 2px;
  position: relative;
  &::after {
    content: 'R';
    font-size: 14px;
    position: relative;
    top: -5px;
    right: 0px;
    color: rgba(#777, 0.7);
  }
}
.bonus__title {
  text-align: right;
  font-size: 14px;
  color: #777;
}
.title--gold {
  color: #c69d4e;
}
.price--gold {
  color: #c69d4e;
  &::after {
    color: rgba(#c69d4e, 0.7);
  }
}

// Dark modifier
.bonus--dark {
  background: $dark-bg;
}
.bonus--dark .bonus__price {
  color: #777;
}
.bonus--dark .bonus__icon {
  background-color: rgba(#fff, 0.05);
  fill: #444;
}

// Inner modifier
.bonus--inner {
  cursor: default;
  background-color: transparent;
}
</style>
