import http from '@service/HttpService'
import qs from 'querystring'

export const getInquiries = (mobile) => {
  const query = qs.stringify({
    mobile
  })
  return http.get('/store/inquiry/?' + query)
}

export const disableTransactionOffer = ({ id }) => {
  const formData = new FormData()
  formData.append('id', id)

  return http.put('/store/sell-offer/', formData)
}
export const disableTransactionBonus = ({ id }) => {
  const formData = new FormData()
  formData.append('id', id)

  return http.put('/store/sell-bonus/', formData)
}

export const receiveCredit = ({ mobile, price }) => {
  const formData = new FormData()
  formData.append('mobile', mobile)
  formData.append('price', price)

  return http.post('/store/inquiry/', formData)
}

export const confirmReceiveCredit = ({ mobile, code }) => {
  const formData = new FormData()
  formData.append('mobile', mobile)
  formData.append('code', code)

  return http.put('/store/inquiry/', formData)
}
