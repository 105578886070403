<template>
  <div class="learn">
    <h3 class="learn__count">{{ count }}</h3>
    <h3 class="learn__text">
      {{ title }}
    </h3>
    <div class="learn__logo">
      <svg
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 700 700"
      >
        <path
          d="M501.13 313.7l-102.23 -59.21 -101.51 -58.52c-9.86,-5.63 -21.14,-7.04 -31.01,-4.22 -9.87,2.81 -19.03,9.16 -24.67,19.03 -2.12,2.81 -2.82,6.34 -4.23,9.87 -0.71,2.12 -0.71,4.93 -1.41,7.75 0,0.71 0,2.12 0,2.82l0 119.85 0 119.83c0,11.28 4.94,21.85 11.98,28.9 7.75,7.75 17.62,11.98 28.9,11.98 6.35,0 19.74,-5.63 23.27,-7.75l100.09 -57.81 0.71 0 0.7 -0.7 103.63 -59.92c9.88,-5.65 16.22,-14.81 19.04,-25.38 0.71,-2.11 0.71,-4.93 0.71,-7.76 0,-23.26 -23.97,-38.76 -23.97,-38.76z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LearnSingle',
  props: {
    title: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 10
    },
    img: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.learn {
  width: 100%;
  height: 85px;
  // border-radius: 15px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 0 20px 0 15px;
  // margin: 0 0 10px;
  overflow: hidden;
  cursor: pointer;
}
.learn__count {
  width: 45px;
  height: 45px;
  font-size: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // top: 3px;
  color: #000;
  border-radius: 10px;
  background-color: rgba(#000, 0);
  padding-top: 6px;
}
.learn__text {
  height: 45px;
  line-height: 45px;
  color: #000;
  text-align: right;
  margin-right: 8px;
  padding-right: 11px;
  font-size: 16px;
  // border-right: 1px solid rgba(#000, 0.3);
}
.learn__logo {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(#000, 0.1);
  svg {
    width: 35px;
    height: 35px;
    fill: rgba(#fff, 0.5);
  }
}
</style>
