<script setup>
import { computed, ref, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { disableTransactionOffer } from '@service/TransactionService'
import { localizeDate, calcExpireTime } from '@util/Time'
import dateConvertor from '@composable/dateConvertor'
import CountDown from '@common/CountDown'

const props = defineProps({
  title: String,
  id: [String, Number],
  priceOld: {
    type: [String, Number],
    defalut: 1
  },
  priceNew: {
    type: [String, Number],
    defalut: 0
  },
  expire: {
    type: String,
    defalut: new Date()
  },
  create: {
    type: String,
    defalut: new Date()
  },
  image: String,
  desc: String
})
const emit = defineEmits(['offerreceived'])

const store = useStore()
const btnLoading = ref(null)
const disableOfferItem = async (id) => {
  btnLoading.value = true
  try {
    await disableTransactionOffer({ id })
    store.dispatch('addToast', {
      type: 'success',
      message: 'آفر با موفقیت دریافت شد.'
    })

    emit('offerreceived')
    btnLoading.value = false
  } catch (ex) {
    console.log(ex)
    store.dispatch('addToast', {
      type: 'error',
      message: 'درخواست شما موفقیت‌آمیز نبود.'
    })
    btnLoading.value = false
  }
}

const diffDay = computed(() => {
  return calcExpireTime(props.create, props.expire)
})
</script>

<template>
  <div class="offer-wrapper">
    <section class="offer">
      <h1 class="offer__title" v-text="title" />
      <section class="offer__info">
        <figure class="offer__img">
          <!-- #TODO Set default img -->
          <img :src="image" />
        </figure>
        <div class="offer__prices">
          <div class="offer__pricePart">
            <h3
              class="offer__price price--old"
              v-text="priceOld.toLocaleString()"
            />
            <h3
              class="offer__price price--new"
              v-text="priceNew.toLocaleString()"
            />
          </div>
          <i class="offer__vr" />
          <div class="offer__pricePart">
            <h4 class="offer__off">OFF</h4>
            <h3
              class="offer__percent"
              v-text="100 - Math.floor((priceNew / priceOld) * 100)"
            />
          </div>
        </div>
        <div class="offer__circles-col">
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
          <i class="offer__circle" />
        </div>
      </section>
      <p class="offer__desc" v-text="desc" />
    </section>
    <div class="time-wrapper">
      <p v-if="diffDay > 1">
        از
        <span>{{ localizeDate(create) }}</span>
        تا
        <span v-text="diffDay - 1" />
        روز
      </p>
      <p v-else>
        <span>{{ localizeDate(expire) }}</span>
      </p>
      <count-down :endDate="expire" :permission="true" class="countdown" />
    </div>
    <div class="credit-buttons">
      <button class="button button--black" @click="disableOfferItem(id)">
        <span class="button__text">دریافت آفر</span>
        <spinner v-if="btnLoading" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
$dark-text-color: #444;
$dark-bg-color-primary: #080808;
$dark-bg-color-secondary: #181818;

.offer-wrapper {
  overflow: hidden;
  padding-top: 20px;
  background-color: #eee;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
// add another div to continue linear-gradient
.offer__desc {
  text-align: justify;
  color: #777;
  font-size: 15px;
  line-height: 20px;
  padding: 10px 10px;
  margin: 10px 0;
}

/** OFFER BLOCK */
.offer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 5%;
}
.offer__title {
  width: calc(100% - 40px);
  padding: 0;
  margin: 0 0 20px;
  position: relative;
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.offer__info {
  background-color: #fff;
  width: 96%;
  border-radius: 10px 10px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.offer__img {
  width: 100%;
  margin: 0;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    filter: saturate(0.9);
  }
}
.offer__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
}
.offer__pricePart {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.offer__price {
  position: relative;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: bold;
  padding-top: 6px;
  &::after {
    content: 'R';
    font-size: 12px;
    top: -6px;
    left: 3px;
    position: relative;
  }
}
.price--old {
  color: #777;
  &::before {
    content: '';
    position: absolute;
    width: 120%;
    background-color: rgba(0, 0, 0, 0.4);
    height: 2px;
    top: 0;
    bottom: 0;
    left: -10%;
    margin: auto 0;
  }
}
.offer__vr {
  width: 1px;
  height: 60px;
  background-color: rgba(#000, 0.2);
}
.price--new {
  color: #c69d4e;
}
.offer__percent {
  color: #000;
  margin: 0 0 3px;
  font-size: 40px;
  position: relative;
  left: -7px;
  &::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    bottom: 8px;
    margin: auto 0;
    width: 12px;
    height: 2px;
    background-color: #000;
  }
  &::after {
    content: '%';
    position: absolute;
    right: -32px;
    top: 0;
    bottom: 0px;
    margin: auto 0;
    // width: 12px;
    // height: 2px;
    // background-color: #000;
  }
}
.offer__off {
  margin: 10px 0 0;
  color: #aaa;
  font-weight: bold;
  font-size: 16px;
}
.offer__circles-col {
  z-index: 4;
  position: absolute;
  bottom: -7px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
}
.offer__circle {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  border-radius: 100%;
  background-color: #eee;
}

.offer__hr {
  width: 100%;
  border-top: 2px dashed rgba(0, 0, 0, 0.3);
}

// Change in common css
.credit-buttons {
  margin: 20px 0 25px;
  .button--black {
    width: 96%;
    .button__text {
      color: #c69d4e;
    }
  }
}
.countdown {
  margin-top: 0;
  padding: 10px 0 15px;
  width: 100%;
}
</style>
