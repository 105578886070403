<template>
  <div class="cmp-wrapper">
    <navbar msg="تراکنش ها" />
    <div class="title-row">
      <div class="payment-title">
        <h1>تراکنش های مالی</h1>
        <h2>
          سیستم ویپون
        </h2>
      </div>
      <a class="title-row__btn" @click.self="modals['filter'] = true">
        <svg viewBox="0 0 512 512">
          <rect y="4.3" width="245.49" height="61.68" />
          <rect x="52.65" y="146.21" width="192.84" height="61.68" />
          <rect x="101.67" y="293.38" width="143.82" height="61.68" />
          <rect x="152.31" y="437.39" width="93.17" height="61.68" />
          <polygon
            points="284.27,394.87 355.57,394.87 355.57,4.3 417.25,4.3 417.25,394.87 488.55,394.87 385.01,512 "
          />
        </svg>
        <span
          class="title-row__remove"
          v-if="selectedFilter['type'] != '0'"
          @click="removeFilter"
        >
          حذف فیلتر
        </span>
      </a>
    </div>
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="getPayments" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <transaction-single
          v-for="payment in payments"
          :key="payment['id']"
          :status="payment['type']"
          :date="localizeDate(payment['created_at'])"
          :hour="getHour(payment['created_at'])"
          :price="payment['price']"
          :count="payment['count']"
          :total="payment['total']"
          :remain="payment['remain']"
          :is-success="payment['status']"
          :relation="payment['relation_id']"
        />
      </div>
      <loader v-show="pageStatus == 'loading' && !firstLoad" />
      <observer @intersect="intersected" />
    </section>
    <modal
      :is-open="modals['filter']"
      @modalClosed="modals['filter'] = false"
      title="فیلتر"
    >
      <div class="available-filter">
        <template v-for="filter in filters" :key="filter">
          <div class="filter-row" @click="() => selectFilter(filter)">
            <h5 :class="filter.type === selectedFilter.type && 'gold'">
              {{ filter['uiText'] }}
            </h5>
          </div>
          <hr />
        </template>
      </div>
    </modal>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'
import TransactionSingle from './subComponents/TransactionSingle'

export default {
  name: 'TransactionList',
  components: {
    TransactionSingle
  },
  setup() {
    // Init store
    const modals = reactive({ filter: false })
    const store = useStore()
    const payments = computed(() => store.state.product.items)
    const page = computed(() => store.state.product.page)

    // Handle loadings
    const pageStatus = computed(() => store.state.product.pageStatus)
    const firstLoad = ref(true)
    watch(pageStatus, (curr) => {
      if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
    })

    // Initial API call
    const query = ref({})
    const getPayments = () => {
      store.dispatch('product/fetchItems', {
        part: 'payment',
        query: { ...query.value }
      })
    }
    getPayments()

    // Implement intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      getPayments()
    }

    // Handle filters
    const filters = [
      { type: '1', uiText: 'ثبت فروش' },
      { type: '2', uiText: 'ایجاد طرح اعتباری' },
      { type: '3', uiText: 'ایجاد آفر' },
      { type: '4', uiText: 'ایجاد بن خرید' },
      { type: '5', uiText: 'ایجاد بونوس' },
      { type: '6', uiText: 'ارسال مناسبت ‌ها' },
      { type: '7', uiText: 'ارسال یادآوری' },
      { type: '8', uiText: 'ارسال اطلاع رسانی' },
      { type: '9', uiText: 'ارسال نظرسنجی' },
      { type: '10', uiText: 'ثبت مشتری' },
      { type: '11', uiText: 'ایجاد قرعه کشی' },
      { type: '12', uiText: 'اشتراک پذیرنده' },
      { type: '13', uiText: 'تراکنش ناموفق', isSuccess: false },
      { type: '13', uiText: 'افزایش موجودی', isSuccess: true },
      { type: '14', uiText: 'افزایش اشتراک' },
      { type: '15', uiText: 'پرداخت از اعتبار' }
    ]
    const selectedFilter = ref({ type: '0', uiText: 'همه' })
    const selectFilter = (filter) => {
      selectedFilter.value = filter
      query.value = { type: filter['type'] }
      store.dispatch('product/clearItems')
      getPayments()
      modals['filter'] = false
    }
    const removeFilter = () => {
      selectedFilter.value = { type: '0', uiText: 'همه' }
      query.value = {}
      store.dispatch('product/clearItems')
      getPayments()
    }

    // Clear reminders
    onBeforeUnmount(() => store.dispatch('product/clearItems'))

    const { localizeDate, getHour } = dateConvertor()

    return {
      page,
      pageStatus,
      firstLoad,
      intersected,
      payments,
      getPayments,
      localizeDate,
      getHour,
      modals,
      filters,
      selectFilter,
      selectedFilter,
      removeFilter
    }
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  background-color: #fff;
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 235px);
}
.title-row {
  width: 100%;
  margin: 0 0 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}
.title-row__btn {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 0 0 10px 1px rgba(#000, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    pointer-events: none;
    width: 22px;
    height: 22px;
    fill: #c69d4e;
  }
}
.title-row__remove {
  position: absolute;
  bottom: 10px;
  right: -100px;
  border-radius: 10px;
  animation: blinking 1.2s infinite;
  background-color: #d00000;
  color: #fff;
  font-size: 12px;
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.payment-title {
  padding: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  h1 {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.3s ease;
    color: #1a1a1a;
  }
  h2 {
    text-align: right;
    font-size: 12px;
    font-weight: bold;
    transition: all 0.3s ease;
    color: #777;
  }
}
// inside modal
.available-filter {
  padding: 10px 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .filter-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 92%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    h5 {
      text-align: center;
      width: 100%;
      color: #111;
      font-size: 16px;
      margin: 5px 0;
      &.gold {
        color: #c69d4e;
      }
    }
  }

  hr {
    width: 92%;
    border-top: 1px solid #aaa;
    margin: 10px 0;
    &:last-child {
      display: none;
    }
  }
}
.filter-exp {
  margin: 15px 0 10px;
  color: #777;
}
</style>
