<template>
  <div :class="['clock', ended && 'clock--ended']">
    <span class="clock__part clock--day">{{ days ? days : '00' }}</span>
    <span class="clock__part clock--hour">{{ hours ? hours : '00' }}</span>
    <span class="clock__part clock--minutes">
      {{ minutes ? minutes : '00' }}
    </span>
    <span class="clock__part clock--seconds">
      {{ seconds ? seconds : '00' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    permission: {
      type: Boolean,
      default: true
    },
    endDate: String
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    },
    _hours() {
      return this._minutes * 60
    },
    _days() {
      return this._hours * 24
    },
    end() {
      if (this.endDate) return new Date(this.endDate)
      else return new Date(this.endYear, this.endMonth, this.endDay)
    }
  },
  data() {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      ended: false,
      timer: null
    }
  },
  methods: {
    calcRemainingTime(date) {
      const now = new Date()
      const inputDate = new Date(date)
      const distance = inputDate.getTime() - now.getTime()
      return distance
    },
    start() {
      this.timer = setInterval(() => {
        const distance = this.calcRemainingTime(this.endDate)
        this.oneClock(distance)
      }, 1000)
    },
    oneClock(distance) {
      if (distance < 0) {
        clearInterval(this.timer)
        this.ended = true
        return
      }
      this.setItems(distance)
    },
    setItems(distance) {
      const days = Math.floor(distance / this._days)
      const hours = Math.floor((distance % this._days) / this._hours)
      const minutes = Math.floor((distance % this._hours) / this._minutes)
      const seconds = Math.floor((distance % this._minutes) / this._seconds)

      this.days = this.formatNum(days)
      this.hours = this.formatNum(hours)
      this.minutes = this.formatNum(minutes)
      this.seconds = this.formatNum(seconds)
    },
    resetItems() {
      this.days = '00'
      this.hours = '00'
      this.minutes = '00'
      this.seconds = '00'
    },
    formatNum(num) {
      return num < 10 ? '0' + num : num
    }
  },
  beforeMount() {
    const distance = this.calcRemainingTime(this.endDate)
    if (!this.permission) {
      this.ended = true
      return
    } else if (distance < 0) {
      this.ended = true
      return
    }
    this.setItems(distance)
  },
  mounted() {
    if (!this.ended) {
      this.start()
    }
  },
  beforeUnmount() {
    if (!this.ended) clearInterval(this.timer)
  },
  watch: {
    permission(newVal) {
      if (!newVal) {
        this.ended = true
        this.resetItems()
        clearInterval(this.timer)
      } else {
        this.ended = false
        this.start()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.clock {
  direction: ltr;
  margin: 10px 0;
  color: white;
  width: 100%;
  border-radius: 10px;
  font-size: 20px;
  text-align: center;
  background-color: #262626;
  padding: 20px 0 40px;
}
.clock__part {
  position: relative;
  display: inline-block;
  text-align: center;
  height: 70px;
  line-height: 70px;
  font-size: 40px;
  width: 65px;
  margin: 0 4px;
  background-color: #1a1a1a;
  border-radius: 10px;
  padding-top: 4px;
  &::after {
    position: absolute;
    color: #777;
    font-size: 13px;
    bottom: -47px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.clock--ended {
  background-color: #181818;
}
.clock--ended .clock__part {
  color: #555;
  background-color: #111;
}
.clock--day::after {
  content: 'روز';
}
.clock--hour::after {
  content: 'ساعت';
}
.clock--minutes::after {
  content: 'دقیقه';
}
.clock--seconds::after {
  content: 'ثانیه';
}
</style>
