<template>
  <div class="cmp-wrapper">
    <navbar msg="درباره ویپون" />
    <p>
      ویپون پلتفرمی جدید و هوشمند است که به کسب‌وکار ها تجربه‌ای جدید در ارتباط
      با مشتریان ارائه می‌کند و باعث افزایش فروش کسب‌وکار می‌شود.
    </p>
    <p>
      ویپون یکی از زیرمجموعه‌های هلدینگ سینوس است که فعالیت خود را از سال ۱۳۹۵
      آغاز نمود.
    </p>
    <p>
      چشم‌انداز‌های ویپون بر سه اصل وفاداری مشتری ،ارتباط هوشمند و افزایش فروش
      کسب‌وکار استوار است
    </p>
    <fixed-footer />
  </div>
</template>

<script>
export default {
  name: 'ProfileAboutUs',
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 20px;
  font-size: 17px;
  width: 100%;
  text-align: justify;
  padding: 0 20px;
  line-height: 22px;
}
</style>
