<template>
  <div class="learn-wrapper">
    <navbar msg="آموزش" back-route="/" />
    <learn-single
      v-for="learn in learnings"
      :key="learn.id"
      :count="learn.id"
      :title="learn['name']"
      :img="learn['img']"
      :link="learn['link']"
      @click="playerIsOpen = true"
    />
    <base-player :isOpen="playerIsOpen" @playerClosed="playerIsOpen = false" />
    <fixed-footer />
  </div>
</template>

<script>
import { ref } from 'vue'
import BasePlayer from '@common/BasePlayer'
import LearnSingle from './subComponents/LearnSingle'
export default {
  name: 'Learn',
  components: {
    BasePlayer,
    LearnSingle
  },
  setup() {
    const learnings = [
      {
        id: 1,
        name: 'آموزش طرح اعتباری',
        img: '',
        link: ''
      },
      {
        id: 2,
        name: 'آموزش آفر',
        img: '',
        link: ''
      },
      {
        id: 3,
        name: 'آموزش بن خرید',
        img: '',
        link: ''
      },
      {
        id: 4,
        name: 'آموزش بونوس',
        img: '',
        link: ''
      },
      {
        id: 5,
        name: 'آموزش نظرسنجی',
        img: '',
        link: ''
      },
      {
        id: 6,
        name: 'آموزش قرعه کشی',
        img: '',
        link: ''
      },
      {
        id: 7,
        name: 'آموزش اطلاع رسانی',
        img: '',
        link: ''
      },
      {
        id: 8,
        name: 'آموزش رویدادها',
        img: '',
        link: ''
      },
      {
        id: 9,
        name: 'آموزش یادآوری',
        img: '',
        link: ''
      }
    ]

    const playerIsOpen = ref(false)

    return { learnings, playerIsOpen }
  }
}
</script>

<style lang="scss" scoped>
.learn-wrapper {
  width: 100%;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #eee;
}
</style>
