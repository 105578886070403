<template>
  <div class="cmp-wrapper">
    <navbar msg="قوانین ویپون" />
    <profile-rule-content />
    <fixed-footer />
  </div>
</template>

<script>
import ProfileRuleContent from './subComponents/ProfileRuleContent'

export default {
  name: 'ProfileRules',
  components: {
    ProfileRuleContent
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  padding: 70px 0;
}
</style>
